.unconfirmed {
	background-color: #fff493;
}

.main-panel {
	background-color: #34312f !important;
}

.main-panel > .content {
	min-height: 100vh !important;
}

.apk-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.apk-button {
	background-color: #fff;
	border-radius: 12px;
	padding: 15px;
	margin: 30px;
}