.navbar.navbar-transparent {
	border-bottom: none !important;
}

.cards {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.card-container {
	display: flex;
	flex-direction: row;
	background-color: #c4efff;
	border-radius: 5px;
	padding: 20px;
	margin: 20px 0;
    width: 100%;
	justify-content: space-between;
}

.frozen-container {
	display: flex;
	flex-direction: row;
	background-color: #ffc1c1;
	border-radius: 5px;
	padding: 20px;
	margin: 20px 0;
    width: 100%;
	justify-content: space-between;
}

.card-container-left {
	display: flex;
	flex-direction: column;
	min-width: 40%;
}

.card-container-right {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-container-left h2 {
	font-size: 24px;
	margin: 5px;
	padding: 0;
}

.card-container-left h3 {
	font-size: 18px;
	margin: 5px;
	padding: 0;
	font-weight: 200;
}

.transaction-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.card-icon {
	margin: 0;
	padding: 0;
}

.card-container-middle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.threshold-container {
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
}

.threshold-container h3 {
	margin: 0;
	padding: 0;
	color: #ffc1c1;
}

.threshold-container p {
	margin: 0;
	padding: 0;
}